import './App.css';

function App() {
  return (
      <div className="App">
          <header className="App-header">
              <h1>No</h1>
              <p>
                  <a className="App-link" href="https://en.wikipedia.org/wiki/Pluto" target="_blank" rel="noopener noreferrer">Pluto</a> is currently a <a className="App-link" href="https://en.wikipedia.org/wiki/Dwarf_planet" target="_blank" rel="noopener noreferrer">dwarf planet</a>
              </p>
          </header>
      </div>
  );
}

export default App;
